<template>
  <b-carousel
    id="carousel-example-generic"
    controls
    indicators
  >

    <b-carousel-slide
      v-for="(imagen, j) in files"
      :key="j"
    >
      <template #img>
        <img
          class="d-block img-fluid w-100 img"
          height="380"
          :src="imagen"
          alt="image slot"
        >
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.img {
  height: 490px !important;
}
</style>
