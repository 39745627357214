<template>
  <div>
    <carousel :files="files" />
  </div>
</template>

<script>
import router from '@/router'
import { findInspeccionId } from '@/utils/inspecciones'
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'Show',
  components: {
    Carousel,
  },
  data() {
    return {
      inspeccion: null,
      files: [],
    }
  },
  async created() {
    this.inspeccion = await findInspeccionId(router.currentRoute.params.id)
    console.log(this.inspeccion)
    this.files = []
    this.inspeccion.imagenes.forEach(imagen => {
      this.files.push(imagen.url)
    })
  },
}
</script>

<style scoped>

</style>
